<template>
  <NLayout has-sider position="absolute" class="layout">
    <NLayoutSider
      :inverted="true"
      show-trigger
      :collapsed="collapsed"
      @collapse="collapsed = true"
      @expand="collapsed = false"
      collapse-mode="width"
      :collapsed-width="64"
      :width="200"
      :native-scrollbar="false"
    >
      <Logo :collapsed="collapsed" />
      <Menu v-model:collapsed="collapsed" />
    </NLayoutSider>
    <NLayout>
      <NLayoutHeader position="absolute">
        <Header />
      </NLayoutHeader>
      <NLayoutContent class="layout-content">
        <div class="layout-content-main">
          <RouterView />
        </div>
      </NLayoutContent>
    </NLayout>
  </NLayout>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { NLayout, NLayoutSider, NLayoutHeader, NLayoutContent, useLoadingBar } from 'naive-ui';
  import Menu from './components/Menu/index.vue';
  import Logo from './components/Logo/index.vue';
  import Header from './components/Header/index.vue';

  const collapsed = ref(false);

  onMounted(() => {
    window.$loadingBar = useLoadingBar();
    window.$loadingBar.finish();
  });
</script>

<style lang="less" scoped>
  .layout {
    display: flex;
    flex-direction: row;
    flex: auto;
  }

  .layout-content {
    min-height: 100vh;
    background-color: #f5f5f5;
  }

  .n-layout-header.n-layout-header--absolute-positioned {
    z-index: 4;
  }

  .layout-content-main {
    margin: 70px 20px 20px;
    // padding: 25px;
    position: relative;
    // background-color: #fff;
  }
</style>