<template>
  <NMenu
    :collapsed-width="64"
    :collapsed-icon-size="20"
    :indent="22"

    :inverted="true"
    :collapsed="collapsed"
    :options="menus"
    :value="curPath"

    @update:value="handleClickMenu"
  />
</template>

<script setup>
  import { ref, computed, h } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { NMenu, NIcon } from 'naive-ui';
  import { SettingsOutline } from '@vicons/ionicons5';

  const store = useStore();
  const router = useRouter();
  const curRoute = useRoute();

  defineProps({
    collapsed: {
      type: Boolean,
    },
  });

  function correctMenus(menus, level = 1) {
    const correctedMenus = [];
    if (Array.isArray(menus) && level < 3) {
      menus.forEach((menu, menuIndex) => {
        let key = '';
        if (menu.link) {
          key = /^\//.test(menu.link) ? menu.link : `/${menu.link}`;
        } else {
          key = `keyLv${level}-${menuIndex}`;
        }
        const correctedMenu = {
          label: menu.title,
          key
        };
        if (level === 1) {
          // 小图标
          if (menu.icon) {
            correctedMenu.icon = () => h('img', {
              src: menu.icon,
              style: 'width: 100%; height: 100%; object-fit: cover;'
            });
          } else {
            correctedMenu.icon = () => h(NIcon, null, { default: () => h(SettingsOutline) })
          }
        }
        if (menu.children && menu.children.length > 0) {
          correctedMenu.children = correctMenus(menu.children, level + 1);
        }
        correctedMenus.push(correctedMenu);
      });
    }
    return correctedMenus.length > 0 ? correctedMenus : undefined;
  }
  const menus = computed(() => correctMenus(store.state.permission.menus));
  function handleClickMenu(path) {
    router.push(path);
  }
  const curPath = computed(() => curRoute.path);

</script>