<template>
  <div class="layout-header-user">
    <NDropdown trigger="hover" @select="handleSelect" :options="options">
      <div class="avatar">
        <NAvatar
          :size="36"
          round
          src="/img/common/logo.png"
        ></NAvatar>
        {{ store.state.permission.username || 'Admin' }}
      </div>
    </NDropdown>
  </div>
</template>

<script setup>
  import { NDropdown, NAvatar } from 'naive-ui';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { logout } from '@/api/permission.js'
  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';
  const { SUCCESS } = resStatusEnum;
  const store = useStore();
  const router = useRouter();

  const options = [
    {
      label: '退出登录',
      key: 'logout'
    }
  ];

  // 账号重置密码
  if (ownUrlPermission('admin/assignment/reset')){
    let arr1 = {
      label: '账号管理',
      key: 'password'
    }
    options.push(arr1)
  }

  function handleSelect(key) {
    switch (key) {
      case 'logout':
        logout().then(res => {
          if (res.code === SUCCESS) {
            store.dispatch('permission/logout').then(res => {
              const message = window.$message;
              message.success('退出成功');
              router.replace('/login');
            }).catch(err => {});
          }
        }).catch(err => {});

        case 'password':
          router.push('/admin/assignment/index/password');
        break;
    }
  }  
</script>

<style lang="less" scoped>
  .layout-header-user {
    display: inline-block;
  }

  .avatar {
    display: flex;
    align-items: center;
    height: 50px;
    line-height: 50px;
    cursor: default;

    :deep(.n-avatar) {
      margin-right: 6px;
    }
  }
</style>
