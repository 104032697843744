<template>
  <NBreadcrumb>
    <NBreadcrumbItem v-for="(route, index) in bredcrumbList" :key="index">
      <span class="breadcrumb-item" :class="{'pre-breadcrumb-item': index < bredcrumbList.length - 1}">{{ route.meta.title }}</span>
    </NBreadcrumbItem>
  </NBreadcrumb>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { NBreadcrumb, NBreadcrumbItem } from 'naive-ui';
  const route = useRoute();

  const bredcrumbList = computed(() => {
    return route.matched.filter(route => route.meta && route.meta.title);
  });
</script>

<style lang="less" scoped>
  .breadcrumb-item {
    cursor: default;
  }

  .pre-breadcrumb-item {
    color: #999;
  }
</style>
