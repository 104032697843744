<template>
  <div class="logo">
    <img :src="logoUrl" :alt="siteName" />
    <h2 v-show="!collapsed">{{ siteName }}</h2>
  </div>
</template>

<script setup>
  import env from '@/settings/env.js';
  const { publicPath, resourceVersion, siteName } = env;
  const logoUrl = `${publicPath}img/common/logo.png?v=${resourceVersion}`;

  defineProps({
    collapsed: Boolean
  });
</script>

<style lang="less" scoped>
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    line-height: 50px;
    overflow: hidden;

    img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      object-fit: cover;
    }

    h2 {
      margin-left: 10px;
      font-size: 14px;
      font-weight: normal;
      white-space: nowrap;
    }
  }
</style>